import Sagas from '/helpers/Sagas'

// For sending to the websocket connection
export function _buildMessage(connection, action, data) {
	let message = data
	message.action = action
	// This is the LITERAL message we send to the websocket
	Sagas.Clients.Emit(connection, 'message', message)
}

const baseUrl = `${
	process.env.REACT_APP_API_URL || 'http://localhost:8000'
}/api`

export function buildImageProxy(url, hash) {
	return `${baseUrl}/public/proxy-image?url=${encodeURIComponent(
		url
	)}&hash=${hash}`
}

export function _buildApiCall(
	endpoint,
	passedData = {},
	parameters = [],
	form = null
) {
	if (form) {
		form.append('meeting_name', MeetingFromUrl())
	}
	let data = {}
	if (endpoint.includeMeetingName) {
		data.meeting_name = MeetingFromUrl()
	}
	data = Object.assign(data, passedData)

	let fullUrl = `${baseUrl}${endpoint.url}`

	// Parameters should be passed as an
	// array of key-value pairs. Example:
	// [['key', 'value'], ['some_param', 'itsvalue']]
	if (parameters && parameters.length > 0) {
		parameters.forEach((p) => {
			fullUrl = fullUrl.replace(`:${p[0]}`, encodeURIComponent(p[1]))
		})
	}

	if (endpoint.method === 'GET' && Object.keys(data).length > 0) {
		fullUrl += `?${Object.keys(data)
			.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
			.join('&')}`
	}

	return new Promise((resolve, reject) => {
		let req = new XMLHttpRequest()
		req.onreadystatechange = (e, d) => {
			if (req.readyState !== 4) {
				return
			}
			let resp
			if (endpoint.method === 'DELETE') {
				resp = req.responseText
			} else {
				try {
					resp = JSON.parse(req.responseText)
				} catch (e) {
					return reject(req.responseText)
				}
			}
			req.status >= 200 && req.status < 300 ? resolve(resp) : reject(resp)
		}
		req.open(endpoint.method, fullUrl, true)
		req.setRequestHeader('Accept', 'application/json')
		!form && req.setRequestHeader('Content-Type', 'application/json')
		endpoint.auth &&
			req.setRequestHeader('Authorization', `Bearer ${endpoint.useMagiclinkToken ? localStorage.magiclink_token : localStorage.token}`)

		if(!!!(endpoint.useMagiclinkToken ? localStorage.magiclink_token : localStorage.token) && endpoint.auth) reject(new Error({ status: 401, msg: 'Invalid Token' }))

		req.send(form || JSON.stringify(data))
	})
}

function MeetingFromUrl() {
	const paths =
		document.URL.indexOf('#') > -1
			? document.URL.substring(0, document.URL.indexOf('#')).split('/')
			: document.URL.split('/')
	return paths[paths.length - 1].split('?')[0]
}
