import React from 'react'
import styled from 'styled-components'
const AllNewNotifications = () => {

    return (
        <Container>
            You have new notifications
        </Container>
    )
    
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export default AllNewNotifications;