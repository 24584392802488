const Purgers = {
	chat: data => {
		if(data?.chats) {
			if(Array.isArray(data.chats)) {
				if (data.chats.length < 50) {
					return data
				}
				data.chats = data.chats.slice(-50)
			} else if (Object.keys(data.chats).length) {
				if(Object.keys(data.chats).length < 50) {
					return data
				}
				const chats = Object.values(data.chats)
								.sort((a, b) => a.timeStamp - b.timeStamp)
								.slice(-50)
								.reduce((acc, chat) => {
									return {
										...acc,
										[chat.id]: chat
									}
								},{})
				data.chats = chats
			}
		}
		return data
	},
}

export default Purgers
