import React from 'react'
import styled from 'styled-components'
import { WidgetActions } from '../../../../stores/MainStore'
import { NotificationActions } from '../../../../stores/NotificationStore'
const { GlobalState } = require('reflux')
import { ModalActions } from '../../../../stores/ModalStore'


export const SetMessage = ({ notification, user }) => {
    let msg = null
    let onClickActions = []
    const authorFname = user.name.split(' ')[0]
    const name = authorFname.length >= 16 ? `${authorFname.substring(0, 19)}...` : authorFname
    switch (notification.widget) {
        case 'chat':
            switch (notification.data.type) {
                case 'mention':
                    msg = (<div>
                        {!!name && name} mentioned you in <span className="link">Chat</span>
                    </div>)
                    break;
            }
            onClickActions.push(() => NotificationActions.ShowChat(true))
            break;
        case 'todo':
            switch (notification.data.type) {
                case 'newtask':
                    msg = (<div>
                        {!!name && name} added you to a <span className="link">Task</span>
                    </div>)
                    break;
            }
            onClickActions.push(() => {
                const todo = GlobalState.main.widgets.todo.todo[notification.data.todoId]
                const tasks = todo['tasks']

                if (tasks[notification.data.taskId] === undefined) {
                    ModalActions.SetModal('TaskDeleted')
                } else {

                    WidgetActions.SetCurrentWidget({ currentWidget: 'todo', localPush: true })
                    NotificationActions.ShowTodo({ todoId: '', taskId: '' })
                    setTimeout(() => {
                        NotificationActions.ShowTodo({
                            todoId: notification.data.todoId,
                            taskId: notification.data.taskId
                        })
                    }, 100)
                }
            })
            break;
    }
    onClickActions.push(() => NotificationActions.WidgetBadgeClick(notification.widget, notification))
    const onClickAction = () => {
        while (onClickActions.length) {
            onClickActions.shift().call()
        }
    }
    return { msg, onClickAction };
}

const NotificationMessages = ({ notification, user }) => {
    const { msg, onClickAction } = SetMessage({ notification, user })

    return (
        <Message onClick={() => onClickAction()}>
            <ItemPhoto>
                <img src={user.avatar_url} alt={user.name.split(' ')[0]} />
            </ItemPhoto>
            {msg}
        </Message>
    )
};

const Message = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .actor {
        font-weight: 500;
        line-height: 24px;
    }

    .link {
        text-decoration: underline;
    }
`

const ItemPhoto = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
`

export default NotificationMessages;